@import '../styles/variables';
@import '../styles/mixins';

.project-detail-page {
  padding: $spacing-3xl 0;
  padding-top: 100px;
  min-height: 100vh;
  background-color: #0a0a0a;
  color: #f5f5f5;
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $spacing-lg;
  }
  
  .page-header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-xl;
    
    .back-button {
      display: flex;
      align-items: center;
      color: $primary-color;
      font-size: $font-size-md;
      text-decoration: none;
      transition: color 0.3s ease;
      margin-right: $spacing-md;
      
      svg {
        margin-right: 8px;
      }
      
      &:hover {
        color: lighten($primary-color, 10%);
      }
    }
    
    .project-title {
      font-size: 2.2rem;
      font-weight: 700;
      margin: 0;
      background: linear-gradient(90deg, #ffffff, #bbbbbb);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
  .project-banner {
    width: 100%;
    height: 450px;
    border-radius: $border-radius-lg;
    overflow: hidden;
    margin-bottom: $spacing-xl;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
    background-color: #131313;
    
    @include tablet {
      height: 400px;
    }
    
    @include mobile {
      height: 300px;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
      cursor: pointer;
      
      &.mobile-app-image {
        object-fit: contain;
        background-color: #131313;
        padding: 20px;
      }
      
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  
  .project-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: $spacing-xl;
    margin-bottom: $spacing-xl;
    
    @include tablet {
      grid-template-columns: 1fr;
    }
    
    .project-info {
      .project-summary, .project-tech, .project-features, .project-challenges {
        margin-bottom: $spacing-xl;
        
        h2 {
          font-size: 1.5rem;
          margin-bottom: $spacing-md;
          color: $primary-color;
          font-weight: 600;
          position: relative;
          padding-bottom: 10px;
          
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 3px;
            background-color: $primary-color;
            border-radius: 3px;
          }
        }
        
        p {
          font-size: 1rem;
          line-height: 1.6;
          color: #dddddd;
          margin-bottom: $spacing-md;
        }
      }
      
      .tech-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        
        .tech-tag {
          background-color: rgba(80, 80, 80, 0.3);
          color: #ffffff;
          padding: 6px 12px;
          border-radius: $border-radius;
          font-size: 0.9rem;
          transition: background-color 0.3s ease;
          border: 1px solid rgba(255, 255, 255, 0.1);
          
          &:hover {
            background-color: rgba(100, 100, 100, 0.4);
          }
        }
      }
      
      .features-list {
        padding-left: 1.5rem;
        
        li {
          margin-bottom: 10px;
          color: #dddddd;
          line-height: 1.5;
          position: relative;
          
          &:before {
            content: '•';
            color: $primary-color;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
      
      .challenge-item {
        background-color: rgba(30, 30, 30, 0.4);
        border-radius: $border-radius;
        padding: $spacing-md;
        margin-bottom: $spacing-md;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
        
        h3 {
          font-size: 1.1rem;
          margin-bottom: 0.5rem;
          color: #ffffff;
          font-weight: 600;
        }
        
        p {
          margin-bottom: 0.5rem;
          font-size: 0.95rem;
        }
        
        .solution {
          color: #bbbbbb;
          font-style: italic;
          
          strong {
            color: $primary-color;
          }
        }
      }
    }
    
    .project-sidebar {
      .project-meta {
        background-color: rgba(30, 30, 30, 0.5);
        border-radius: $border-radius;
        padding: $spacing-md;
        margin-bottom: $spacing-md;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
        
        .meta-item {
          padding: $spacing-sm 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
          
          &:last-child {
            border-bottom: none;
          }
          
          h3 {
            font-size: 0.9rem;
            margin-bottom: 5px;
            color: #999999;
            font-weight: 500;
            text-transform: uppercase;
          }
          
          p {
            font-size: 1rem;
            color: #ffffff;
            font-weight: 500;
          }
        }
      }
    }
  }
  
  .project-gallery {
    margin-bottom: $spacing-xl;
    
    h2 {
      font-size: 1.5rem;
      margin-bottom: $spacing-md;
      color: $primary-color;
      font-weight: 600;
      position: relative;
      padding-bottom: 10px;
      
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 3px;
        background-color: $primary-color;
        border-radius: 3px;
      }
    }
    
    .gallery-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-md;
      
      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }
      
      @include mobile {
        grid-template-columns: 1fr;
      }
      
      .gallery-item {
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        background-color: rgba(10, 10, 10, 0.9);
        
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 20px 30px rgba(0, 0, 0, 0.6);
        }
        
        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
          
          &.mobile-app-image {
            object-fit: contain;
            background-color: #131313;
            padding: 10px;
          }
          
          @include mobile {
            height: 240px;
          }
        }
      }
    }
  }
  
  .related-projects {
    h2 {
      font-size: 1.5rem;
      margin-bottom: $spacing-md;
      color: $primary-color;
      font-weight: 600;
      position: relative;
      padding-bottom: 10px;
      
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 3px;
        background-color: $primary-color;
        border-radius: 3px;
      }
    }
    
    .related-projects-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-md;
      
      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }
      
      @include mobile {
        grid-template-columns: 1fr;
      }
      
      .related-project-card {
        background-color: rgba(30, 30, 30, 0.7);
        border-radius: $border-radius;
        overflow: hidden;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
        text-decoration: none;
        display: block;
        cursor: pointer;
        
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
        }
        
        &:focus {
          outline: 2px solid $primary-color;
          transform: translateY(-5px);
        }
        
        .related-project-image {
          height: 180px;
          overflow: hidden;
          
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease;
            
            &.mobile-app-image {
              object-fit: contain;
              background-color: #131313;
              padding: 10px;
            }
            
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        
        h3 {
          font-size: 1.1rem;
          padding: 1rem;
          margin: 0;
          color: white;
          font-weight: 500;
        }
      }
    }
  }
}

.project-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  
  h2 {
    font-size: 2rem;
    margin-bottom: $spacing-md;
    color: $text-color;
  }
  
  p {
    font-size: 1.1rem;
    margin-bottom: $spacing-lg;
    color: #999999;
  }
  
  .btn {
    display: inline-flex;
    align-items: center;
    
    .icon-left {
      margin-right: 8px;
    }
  }
}

// Fullscreen image overlay
.fullscreen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .fullscreen-image-container {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    }
    
    .close-fullscreen {
      position: absolute;
      top: -40px;
      right: 0;
      background: none;
      border: none;
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.2s ease;
      
      &:hover {
        opacity: 1;
      }
    }
  }
} 
@import '../../../styles/variables';
@import '../../../styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;
  padding: $spacing-md 0;
  background-color: rgba(10, 10, 10, 0.75);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  
  &.scrolled {
    background-color: rgba(10, 10, 10, 0.9);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $spacing-md;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: $spacing-sm;
    text-decoration: none;
    color: white;
    transition: opacity 0.2s ease;
    
    &:hover {
      opacity: 0.9;
    }
    
    .logo-image {
      height: 40px;
      width: auto;
      border-radius: 8px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }
    
    .logo-text {
      display: flex;
      flex-direction: column;
      
      .name {
        color: white;
        font-size: $font-size-lg;
        font-weight: 700;
        letter-spacing: 0.5px;
      }
      
      .title {
        font-size: $font-size-xs;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  
  .navigation {
    .nav-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: $spacing-md;
      
      .nav-item {
        margin: 0 1rem;
        
        @include mobile {
          margin: 0;
          width: 100%;
          text-align: center;
        }
        
        .nav-link {
          display: flex;
          align-items: center;
          color: #fff;
          text-decoration: none;
          font-weight: 500;
          padding: 0.5rem 0;
          position: relative;
          transition: color 0.3s;
          cursor: pointer;
          
          &:hover, &.active {
            color: $primary-color;
          }
          
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background-color: $primary-color;
            transition: width 0.3s;
          }
          
          &:hover::after, &.active::after {
            width: 100%;
          }
          
          svg {
            margin-right: 0.5rem;
            font-size: 1rem;
          }
          
          @include mobile {
            padding: 1rem 0;
            font-size: 1.1rem;
            justify-content: center;
          }
        }
      }
    }
  }
  
  .mobile-menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 200;
    
    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: white;
      border-radius: 2px;
      transition: all 0.3s ease;
    }
    
    &.active {
      span:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
      }
      
      span:nth-child(2) {
        opacity: 0;
      }
      
      span:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
      }
    }
  }
  
  @include tablet {
    .mobile-menu-toggle {
      display: flex;
    }
    
    .navigation {
      position: fixed;
      top: 0;
      right: -100%;
      width: 70%;
      height: 100vh;
      background-color: rgba(15, 15, 15, 0.95);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: right 0.3s ease;
      z-index: 150;
      box-shadow: -5px 0 30px rgba(0, 0, 0, 0.3);
      border-left: 1px solid rgba(255, 255, 255, 0.05);
      
      &.open {
        right: 0;
      }
      
      .nav-list {
        flex-direction: column;
        align-items: center;
        gap: $spacing-lg;
        
        .nav-item {
          a {
            font-size: $font-size-lg;
            gap: $spacing-sm;
            
            svg {
              font-size: $font-size-lg;
            }
          }
        }
      }
    }
  }
} 
// Responsive breakpoints
@mixin respond-to($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: $breakpoint-sm) { @content; }
  } @else if $breakpoint == md {
    @media (min-width: $breakpoint-md) { @content; }
  } @else if $breakpoint == lg {
    @media (min-width: $breakpoint-lg) { @content; }
  } @else if $breakpoint == xl {
    @media (min-width: $breakpoint-xl) { @content; }
  }
}

// Mobile and tablet mixins for easier usage
@mixin mobile {
  @media (max-width: ($breakpoint-md - 1px)) { @content; }
}

@mixin tablet {
  @media (max-width: ($breakpoint-lg - 1px)) { @content; }
}

// Flex utilities
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

// Container
@mixin container {
  width: 100%;
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding;
  padding-right: $container-padding;
}

// Box shadow
@mixin box-shadow($level: 1) {
  @if $level == 1 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  } @else if $level == 2 {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  } @else if $level == 3 {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
}

// Transitions
@mixin transition($property: all, $duration: $transition-medium) {
  transition: $property $duration;
}

// Button styles
@mixin button-base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-sm $spacing-lg;
  border-radius: $border-radius-md;
  font-weight: 600;
  font-size: $font-size-md;
  cursor: pointer;
  outline: none;
  border: none;
  text-decoration: none;
  @include transition;
}

@mixin button-primary {
  @include button-base;
  background-color: $accent-color;
  color: $light-text;
  
  &:hover {
    background-color: darken($accent-color, 10%);
  }
  
  &:active {
    background-color: darken($accent-color, 15%);
  }
}

@mixin button-secondary {
  @include button-base;
  background-color: transparent;
  color: $accent-color;
  border: 1px solid $accent-color;
  
  &:hover {
    background-color: rgba($accent-color, 0.1);
  }
  
  &:active {
    background-color: rgba($accent-color, 0.2);
  }
}

// Card styles
@mixin card {
  background-color: $light-bg;
  border-radius: $border-radius-lg;
  overflow: hidden;
  @include box-shadow(1);
  @include transition;
  
  &:hover {
    @include box-shadow(2);
    transform: translateY(-5px);
  }
}

// Truncate text with ellipsis
@mixin truncate($lines: 1) {
  @if $lines == 1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// Grid layout
@mixin grid($columns: 1, $gap: $spacing-md) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: $gap;
}

// Center absolute
@mixin center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} 
@import '../styles/variables';
@import '../styles/mixins';

.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 200px);
  padding: $spacing-xl 0;
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $spacing-md;
  }
  
  .not-found-content {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .error-code {
    font-size: 8rem;
    font-weight: 800;
    margin: 0;
    color: $primary-color;
    line-height: 1;
    opacity: 0.8;
    text-shadow: 0 4px 20px rgba($primary-color, 0.2);
    
    @include mobile {
      font-size: 6rem;
    }
  }
  
  .error-title {
    font-size: 2.5rem;
    margin: $spacing-sm 0 $spacing-md;
    color: $text-color;
    
    @include mobile {
      font-size: 2rem;
    }
  }
  
  .error-message {
    font-size: 1.2rem;
    color: $text-color-secondary;
    margin-bottom: $spacing-xl;
    line-height: 1.6;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    gap: $spacing-md;
    
    @include mobile {
      flex-direction: column;
      align-items: center;
    }
    
    .btn {
      display: inline-flex;
      align-items: center;
      padding: $spacing-sm $spacing-lg;
      
      .icon-left {
        margin-right: $spacing-xs;
      }
    }
  }
} 
@import '../styles/variables.scss';
@import '../styles/mixins';

.skills-page {
  background-color: #0a0a0a;
  min-height: 100vh;
  padding: 8rem 0 6rem;
  color: white;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .page-header {
    text-align: center;
    margin-bottom: 4rem;

    .page-title {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: white;
    }

    .page-description {
      font-size: 1.2rem;
      opacity: 0.8;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .skills-categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 3rem;

    .category-button {
      padding: 0.8rem 1.5rem;
      border: none;
      border-radius: 50px;
      background-color: rgba(30, 30, 30, 0.7);
      color: white;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: rgba(50, 50, 50, 0.7);
        transform: translateY(-2px);
      }

      &.active {
        background-color: $primary-color;
        box-shadow: 0 4px 12px rgba($primary-color, 0.3);
      }
    }
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1.2rem;
    margin-bottom: 5rem;

    .skill-card {
      background-color: rgba(30, 30, 30, 0.7);
      border-radius: 0.8rem;
      padding: 1.2rem;
      transition: all 0.3s ease;
      box-shadow: 0 8px 12px -3px rgba(0,0,0,0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 20px -5px rgba(0,0,0,0.1);
        background-color: rgba(40, 40, 40, 0.7);
      }

      .skill-icon-wrapper {
        background-color: rgba(20, 20, 20, 0.7);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.8rem;

        .skill-icon {
          font-size: 1.8rem;
        }

        .skill-custom-logo {
          width: 35px;
          height: 35px;
          transition: transform 0.3s ease;
          
          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .skill-name {
        font-size: 1.1rem;
        margin-bottom: 0.3rem;
        color: white;
      }

      .skill-experience {
        font-size: 0.8rem;
        color: $primary-color;
        opacity: 0.9;
      }
    }
  }

  .learning-goals {
    padding: 3rem;
    border-radius: 1rem;
    background-color: rgba(30, 30, 30, 0.7);

    .section-title {
      text-align: center;
      margin-bottom: 2.5rem;
      font-size: 2rem;
      color: white;
    }

    .goals-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1.5rem;

      .goal-item {
        background-color: rgba(40, 40, 40, 0.7);
        padding: 1rem;
        border-radius: 0.5rem;
        text-align: center;
        transition: all 0.3s ease;
        
        &:hover {
          transform: translateY(-3px);
          background-color: rgba(50, 50, 50, 0.7);
          box-shadow: 0 8px 15px rgba(0,0,0,0.1);
        }
      }
    }
  }

  // Media Queries
  @media (max-width: 768px) {
    padding: 7rem 0 4rem;

    .page-header {
      margin-bottom: 3rem;

      .page-title {
        font-size: 2.5rem;
      }
    }
    
    .skills-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1rem;
    }
    
    .learning-goals {
      padding: 2rem;
      
      .goals-list {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
    }
  }

  @media (max-width: 480px) {
    padding: 6rem 0 3rem;
    
    .container {
      padding: 0 1.5rem;
    }
    
    .page-header {
      .page-title {
        font-size: 2rem;
      }
    }
    
    .skills-categories {
      .category-button {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
      }
    }
    
    .skills-grid {
      grid-template-columns: 1fr;
    }
    
    .learning-goals {
      padding: 1.5rem;
      
      .section-title {
        font-size: 1.8rem;
      }
      
      .goals-list {
        grid-template-columns: 1fr;
      }
    }
  }
}

/* Skills Categories */
.skills-categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $spacing-sm;
  margin-bottom: $spacing-xl;
  
  .category-button {
    background-color: rgba(30, 30, 30, 0.7);
    color: $light-text;
    border: 2px solid rgba(255, 255, 255, 0.1);
    padding: $spacing-sm $spacing-md;
    border-radius: $border-radius-md;
    font-size: $font-size-sm;
    font-weight: 500;
    cursor: pointer;
    transition: all $transition-fast;
    
    &:hover {
      background-color: rgba(50, 50, 50, 0.7);
      border-color: rgba(255, 255, 255, 0.2);
    }
    
    &.active {
      background-color: $accent-color;
      color: $light-text;
      border-color: $accent-color;
    }
  }
}

/* Skills Grid */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing-md;
  margin-bottom: $spacing-2xl;
  
  @include respond-to(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @include respond-to(md) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @include respond-to(lg) {
    grid-template-columns: repeat(5, 1fr);
    gap: $spacing-lg;
  }
}

.skill-card {
  position: relative;
  background-color: rgba(30, 30, 30, 0.7);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius-md;
  padding: $spacing-md;
  text-align: center;
  transition: all $transition-medium;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.2);
  }
  
  .skill-icon-wrapper {
    font-size: 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-sm;
  }
  
  .skill-icon {
    transition: color $transition-medium;
  }
  
  .skill-name {
    font-size: $font-size-md;
    margin: 0 0 $spacing-xs;
    font-weight: 500;
    color: $light-text;
  }
  
  .skill-experience {
    font-size: $font-size-sm;
    margin: 0;
    color: #bbbbbb;
  }
}

/* Skills Legend */
.skills-legend {
  background-color: $light-gray;
  border-radius: $border-radius-lg;
  padding: $spacing-md;
  margin-bottom: $spacing-xl;
  
  .legend-title {
    font-size: $font-size-md;
    margin-bottom: $spacing-sm;
    font-weight: 600;
  }
  
  .legend-items {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-md;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    
    .level-indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: $spacing-xs;
      
      &.beginner {
        background-color: #ffd166;
      }
      
      &.intermediate {
        background-color: #06d6a0;
      }
      
      &.advanced {
        background-color: #118ab2;
      }
    }
    
    .level-name {
      font-size: $font-size-sm;
    }
  }
}

/* Learning Goals */
.learning-goals {
  margin-top: $spacing-xl;
  
  .section-title {
    font-size: $font-size-xl;
    margin-bottom: $spacing-md;
    text-align: center;
    color: $light-text;
  }
  
  .goals-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-md;
    
    @include respond-to(md) {
      grid-template-columns: repeat(5, 1fr);
    }
    
    .goal-item {
      background-color: rgba(30, 30, 30, 0.7);
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: $border-radius-md;
      padding: $spacing-md;
      text-align: center;
      font-size: $font-size-sm;
      color: #bbbbbb;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      transition: all $transition-medium;
      
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
        border-color: rgba(255, 255, 255, 0.2);
        color: $light-text;
      }
    }
  }
} 
@import '../styles/variables';
@import '../styles/mixins';

.contact-page {
  padding: $spacing-3xl 0 0;
  background-color: #0a0a0a;
  color: #ffffff;
  min-height: 100vh;
  padding-top: calc(80px + $spacing-3xl); /* Zusätzliches Padding für den Header */
  margin-bottom: 0;
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $spacing-md;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: $spacing-2xl;
    
    h1 {
      font-size: $font-size-3xl;
      font-weight: 700;
      margin-bottom: $spacing-md;
      position: relative;
      display: inline-block;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 3px;
        background-color: $accent-color;
        border-radius: 2px;
      }
    }
    
    p {
      max-width: 700px;
      margin: $spacing-lg auto 0;
      font-size: $font-size-lg;
      color: #bbbbbb;
      line-height: 1.6;
    }
  }
  
  .contact-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $spacing-2xl;
    
    @include tablet {
      grid-template-columns: 1fr;
      gap: $spacing-xl;
    }
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: $spacing-lg;
    margin-bottom: $spacing-2xl;
    
    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    @include mobile {
      flex-direction: column;
    }
    
    .info-card {
      background-color: rgba(30, 30, 30, 0.5);
      padding: $spacing-lg;
      border-radius: 16px;
      text-align: center;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border: 2px solid rgba(255, 255, 255, 0.05);
      
      @include tablet {
        flex: 1;
        min-width: 200px;
        max-width: 280px;
      }
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
      }
      
      .info-icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: rgba($accent-color, 0.15);
        color: $accent-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto $spacing-md;
        font-size: $font-size-xl;
        transition: all 0.3s ease;
        
        svg {
          filter: drop-shadow(0 0 5px rgba($accent-color, 0.5));
        }
      }
      
      &:hover .info-icon {
        background-color: rgba($accent-color, 0.25);
        transform: scale(1.1);
      }
      
      h3 {
        font-size: $font-size-lg;
        font-weight: 600;
        margin-bottom: $spacing-sm;
        color: #ffffff;
      }
      
      p {
        color: #bbbbbb;
        margin-bottom: $spacing-md;
      }
      
      .info-link {
        display: inline-block;
        padding: $spacing-xs $spacing-sm;
        background-color: rgba($accent-color, 0.15);
        color: $accent-color;
        border-radius: 8px;
        font-weight: 500;
        transition: all 0.3s ease;
        
        &:hover {
          background-color: rgba($accent-color, 0.25);
          transform: translateY(-2px);
        }
      }
    }
  }
  
  .contact-form-container {
    background-color: rgba(30, 30, 30, 0.5);
    padding: $spacing-xl;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.05);
    margin-bottom: $spacing-2xl;
    
    @include mobile {
      padding: $spacing-lg;
    }
    
    .form-message {
      text-align: center;
      padding: $spacing-xl;
      
      .message-icon {
        font-size: 3rem;
        margin-bottom: $spacing-md;
        
        svg {
          filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.1));
        }
      }
      
      p {
        font-size: $font-size-lg;
        margin-bottom: $spacing-lg;
        line-height: 1.6;
      }
      
      &.success .message-icon {
        color: rgb(80, 200, 120);
      }
      
      &.error {
        .message-icon {
          color: rgb(255, 100, 100);
        }
        
        .try-again-btn {
          background-color: rgba(255, 100, 100, 0.1);
          color: rgb(255, 100, 100);
          border: 1px solid rgba(255, 100, 100, 0.2);
          padding: $spacing-sm $spacing-lg;
          border-radius: 8px;
          font-weight: 500;
          transition: all 0.3s ease;
          
          &:hover {
            background-color: rgba(255, 100, 100, 0.2);
          }
        }
      }
    }
    
    .contact-form {
      .form-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $spacing-md;
        
        @include mobile {
          grid-template-columns: 1fr;
        }
      }
      
      .form-group {
        margin-bottom: $spacing-md;
        
        label {
          display: block;
          margin-bottom: $spacing-xs;
          font-weight: 500;
          color: #dddddd;
        }
        
        input, textarea {
          width: 100%;
          padding: $spacing-sm;
          background-color: rgba(255, 255, 255, 0.05);
          border: 2px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          color: #ffffff;
          font-family: inherit;
          transition: all 0.3s ease;
          
          &:focus {
            outline: none;
            border-color: rgba($accent-color, 0.5);
            background-color: rgba(255, 255, 255, 0.08);
          }
          
          &.error {
            border-color: rgba(255, 75, 75, 0.5);
            background-color: rgba(255, 75, 75, 0.05);
          }
        }
        
        textarea {
          resize: vertical;
          min-height: 120px;
        }
        
        .error-message {
          display: block;
          margin-top: $spacing-xs;
          color: rgb(255, 100, 100);
          font-size: $font-size-sm;
        }
      }
      
      .submit-btn {
        background-color: $accent-color;
        color: white;
        padding: $spacing-sm $spacing-lg;
        border-radius: 8px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-xs;
        transition: all 0.3s ease;
        box-shadow: 0 4px 12px rgba($accent-color, 0.3);
        width: 100%;
        margin-top: $spacing-md;
        
        .icon-right {
          transition: transform 0.2s ease;
        }
        
        &:hover {
          background-color: darken($accent-color, 5%);
          box-shadow: 0 8px 20px rgba($accent-color, 0.4);
          transform: translateY(-3px);
          
          .icon-right {
            transform: translateX(4px);
          }
        }
      }
    }
  }
} 
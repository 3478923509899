// Colors
$accent-color: #7B61FF;
$primary-color: #7B61FF;
$light-bg: #ffffff;
$dark-text: #1a1a1a;
$light-text: #ffffff;
$gray-text: #666666;
$light-gray: #f4f4f4;
$medium-gray: #e0e0e0;
$dark-gray: #333333;
$shadow-color: rgba(0, 0, 0, 0.1);
$success-color: #4caf50;
$error-color: #f44336;

// Theme colors 
$background-primary: $light-bg;
$background-secondary: $light-gray;
$text-primary: $dark-text;
$text-secondary: $gray-text;
$text-color: $dark-text;
$text-color-secondary: $gray-text;
$text-color-tertiary: #999999;
$border-color: $medium-gray;

// Background colors
$bg-light: $light-gray;

// Box shadows
$box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
$box-shadow-light: 0 2px 10px rgba(0, 0, 0, 0.05);

// Typography
$font-family-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-family-secondary: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

$font-size-xs: 0.75rem;   // 12px
$font-size-sm: 0.875rem;  // 14px
$font-size-md: 1rem;      // 16px
$font-size-lg: 1.25rem;   // 20px
$font-size-xl: 1.5rem;    // 24px
$font-size-xxl: 2rem;     // 32px
$font-size-3xl: 2.5rem;   // 40px
$font-size-4xl: 3rem;     // 48px

// Spacing
$spacing-xs: 0.25rem;    // 4px
$spacing-sm: 0.5rem;     // 8px
$spacing-md: 1rem;       // 16px
$spacing-lg: 1.5rem;     // 24px
$spacing-xl: 2rem;       // 32px
$spacing-2xl: 3rem;      // 48px
$spacing-3xl: 4rem;      // 64px

// Borders
$border-radius: 0.25rem;
$border-radius-sm: 0.25rem;   // 4px
$border-radius-md: 0.5rem;    // 8px
$border-radius-lg: 0.75rem;   // 12px
$border-radius-xl: 1rem;      // 16px
$border-radius-circle: 50%;

// Transitions
$transition-fast: 0.2s ease;
$transition-medium: 0.3s ease;
$transition-slow: 0.5s ease;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Container widths
$container-max-width: 1140px;
$container-padding: $spacing-md;

// Z-index
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-fixed: 1030;
$z-index-modal: 1040;
$z-index-tooltip: 1050; 